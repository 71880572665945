import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { ThemeProvider } from '@creditas/stylitas'
import tokens from '@creditas/tokens'

import { TranslatedErrorBoundary } from '../../components'
import { ApolloClient } from '../../services/bff/apollo-client'
import { OverviewCustomProps } from './index.spa'
import { useGetSummary } from '../../services/bff/queries'
import { Overview } from './Overview'

class App extends React.Component<OverviewCustomProps> {
  componentDidCatch() {}

  render() {
    const { authToken, clientId } = this.props
    const apolloClient = ApolloClient.getAuthorizedClient(authToken)
    return (
      <TranslatedErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={{ ...tokens }}>
            <Overview clientId={clientId} request={useGetSummary} />
          </ThemeProvider>
        </ApolloProvider>
      </TranslatedErrorBoundary>
    )
  }
}

export { App }
